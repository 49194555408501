import { FC } from "react";
import BaseDropdown from "components/baseDropdown/baseDropdown";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/helperFunctions";
import { handleChangeLanguage } from "utils/helperFunctions";

const languages = getLanguages();

interface GlobalLangaugeSelectorProps {
    className?: string;
}

export const GlobalLangaugeSelector: FC<GlobalLangaugeSelectorProps> = ({ className }) => {
    const { i18n } = useTranslation();

    const updateLanguage = (_: any, event: any) => {
        handleChangeLanguage(event.target.text);
    };

    return (
        <BaseDropdown className={className} size={"small"} onSelect={updateLanguage} toggleText={i18n.resolvedLanguage}>
            {languages.map(mappedLanguage => {
                return (
                    <Dropdown.Item key={mappedLanguage.value} eventKey={mappedLanguage.value}>
                        {mappedLanguage.name}
                    </Dropdown.Item>
                );
            })}
        </BaseDropdown>
    );
};
