import { PageTypeEnum } from "utils/enums";
import { kycLanguageDetect } from "utils/helperFunctions";

export const StringResources = {
    modal: {
        close: "modal.close",
        confirm: "modal.confirm",
        delete: "modal.delete",
        cancel: "modal.cancel",
        set: "modal.set",
        details: "modal.details",
        submit: "modal.submit",
        feedback: {
            title: "modal.feedback.title",
            label: "modal.feedback.label",
            placeholder: "modal.feedback.placeholder",
            success: "modal.feedback.success",
            error: "modal.feedback.error",
            message: "modal.feedback.message",
        },
        column: "modal.column",
        filter: "modal.filter",
        deleteModal: {
            title: "modal.deleteModal.title",
            message: "modal.deleteModal.message",
        },
    },
    errorBoundary: {
        title: "errorBoundary.title",
        subtitle: "errorBoundary.subtitle",
        btnText: "errorBoundary.btnText",
    },
    mainNavigation: {
        dashboard: "mainNavigation.dashboard",
        payments: "mainNavigation.payments",
        terminals: "mainNavigation.terminals",
        agreements: "mainNavigation.agreements",
        dailyBalance: "mainNavigation.dailyBalance",
        settlements: "mainNavigation.settlements",
        batches: "mainNavigation.batches",
        transactions: "mainNavigation.transactions",
        authorizations: "mainNavigation.authorizations",
        disputes: "mainNavigation.disputes",
        users: "mainNavigation.users",
        electronicDocuments: "mainNavigation.electronicDocuments",
        notifications: "mainNavigation.notifications",
        settings: "mainNavigation.settings",
        logout: "mainNavigation.logout",
        termsOfService: "mainNavigation.termsOfService",
    },
    userMenu: {
        back: "userMenu.back",
    },
    table: {
        cell: {
            boolean: {
                yesLabel: "table.cell.boolean.yesLabel",
                noLabel: "table.cell.boolean.noLabel",
            },
        },
        fallbackMessage: {
            default: "table.fallbackMessage.default",
            [PageTypeEnum.None]: "table.fallbackMessage.default",
            [PageTypeEnum.NotFound]: "table.fallbackMessage.default",
        },
        fallbackMessageWithFilter: "table.fallbackMessageWithFilter",
        itemsPerPage: "table.itemsPerPage",
    },
    report: {
        export: "report.export",
        print: "report.print",
    },
    toolTip: {

    },
    pages: {
        [PageTypeEnum.NotFound]: {
            title: "pages.notFound.title",
            description: "pages.notFound.description",
            link: "pages.notFound.link",
        },
        [PageTypeEnum.None]: {
            title: "pages.none.title",
        },
        terms: {
            title: "pages.terms.title",
            descriptionFirst: "pages.terms.descriptionFirst",
            descriptionLink: "pages.terms.descriptionLink",
            descriptionSecond: "pages.terms.descriptionSecond",
            link: "pages.terms.link",
            conditions: "pages.terms.conditions",
            acceptTerms: "pages.terms.acceptTerms",
            back: "pages.terms.back",

            userMessageAcceptTerms: "pages.terms.userMessageAcceptTerms",
            selectMerchant: "pages.terms.selectMerchant",
            newTermsAvailable: {
                first: "pages.terms.newTermsAvailable.first",
                second: "pages.terms.newTermsAvailable.second",
                third: "pages.terms.newTermsAvailable.third",
            },
        },
    },
    input: {
        passwordShowCaption: "input.passwordShowCaption",
        passwordHideCaption: "input.passwordHideCaption",
        passwordStrengthBarLabel: "input.passwordStrengthBarLabel",
        passwordStrengthBad: "input.passwordStrengthBad",
        passwordStrengthWeak: "input.passwordStrengthWeak",
        passwordStrengthPassable: "input.passwordStrengthPassable",
        passwordStrengthGood: "input.passwordStrengthGood",
        passwordStrengthVeryGood: "input.passwordStrengthVeryGood",
    },
    login: {
        backActionLabel: "login.backActionLabel",
        title: "login.title",
        formTitle: "login.formTitle",
        formImpersonateTitle: "login.formImpersonateTitle",
        formEmailInputLabel: "login.formEmailInputLabel",
        formEmailValidationMsg: "login.formEmailValidationMsg",
        formPasswordInputLabel: "login.formPasswordInputLabel",
        formButtonCaption: "login.formButtonCaption",
        forgotPasswordLink: "login.forgotPasswordLink",
        loginFailed: "login.loginFailed",
    },
    multiselect: {
        selected: "multiselect.selected",
        options: "multiselect.options",
        selectAll: "multiselect.selectAll",
        clearAll: "multiselect.clearAll",
    },

    versionCheck: {
        text: "versionCheck.text",
        button: "versionCheck.button",
    },
    kycType: {
        title: "kycType.title",
        description: "kycType.description",
        individual: "kycType.individual",
        individualDescription: "kycType.individualDescription",
        company: "kycType.company",
        companyDescription: "kycType.companyDescription",
    },
    kyc: {
        backActionLabel: "kyc.backActionLabel",
        title: "kyc.title",
        formTitle: "kyc.formTitle",
        formPhoneNumberInputLabel: "kyc.formPhoneNumberInputLabel",
        formButtonCaption: "kyc.formButtonCaption",
        kycFailed: "kyc.kycFailed",
        audkenniAppLogin: "kyc.audkenniAppLogin",
        ssn: "kyc.ssn",
        controlCode: "kyc.controlCode",
        audkenniTabTitle: "kyc.audkenniTabTitle",
        audkenniAppTabTitle: "kyc.audkenniAppTabTitle",
    },
    kycLegalEntity: {
        backActionLabel: "kycLegalEntity.backActionLabel",
        title: "kycLegalEntity.title",
        subTitle: "kycLegalEntity.subTitle",
        empty: "kycLegalEntity.empty",
    },
    kycSurvey: {
        backActionLabel: "kycSurvey.backActionLabel",
        title: "kycSurvey.title",
        changeText: "kycSurvey.changeText",
        changeButton: "kycSurvey.changeButton",
        formButtonCaption: "kycSurvey.formButtonCaption",
        fetchQuestionarieError: "kycSurvey.fetchQuestionarieError",
        genericError: "kycSurvey.genericError",
        error: "kycSurvey.error",
        reviewAnswer: "kycSurvey.reviewAnswer",
        beneficialOwner: {
            title: "kycSurvey.beneficialOwner.title",
            fullName: "kycSurvey.beneficialOwner.fullName",
            ssn: "kycSurvey.beneficialOwner.ssn",
            phone: "kycSurvey.beneficialOwner.phone",
            shares: "kycSurvey.beneficialOwner.shares",
            addPerson: "kycSurvey.beneficialOwner.addPerson",
        },
        legalBinder: {
            title: "kycSurvey.legalBinder.title",
            fullName: "kycSurvey.legalBinder.fullName",
            ssn: "kycSurvey.legalBinder.ssn",
            phone: "kycSurvey.legalBinder.phone",
            position: "kycSurvey.legalBinder.position",
            addPerson: "kycSurvey.legalBinder.addPerson",
            positions: {
                boardMember: "kycSurvey.legalBinder.positions.boardMember",
                ceo: "kycSurvey.legalBinder.positions.ceo",
                chairmanOfTheBoard: "kycSurvey.legalBinder.positions.chairmanOfTheBoard",
                comanager: "kycSurvey.legalBinder.positions.comanager",
                other: "kycSurvey.legalBinder.positions.other",
                powerOfAttorney: "kycSurvey.legalBinder.positions.powerOfAttorney",
            },
        },
    },
    kycSuccess: {
        backActionLabel: "kycSuccess.backActionLabel",
        title: "kycSuccess.title",
        subTitle: "kycSuccess.titsubTitlele",
        buttonCaption: "kycSuccess.buttonCaption",
    },
};
