import { StringResources } from "utils/language/languageResource";
import { AngleIcon, KnightIcon } from "components/icons";
import "./kycType.scss";
import { GlobalLangaugeSelector } from "components/languageSelector/languageSelector";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { PATHS } from "utils/routing/paths";
import { ICompanySchema } from "redux/models/kycModels";
import logo from "assets/images/logo.svg";

export interface IKycCompanySchema {
    authorizationToken: string;
    relatedCompanies: ICompanySchema[];
}

const KycType = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    async function handleIndividualClick(): Promise<void> {
        navigate(PATHS.Global.IndividualKyc);
    }

    async function handleCompanyClick(): Promise<void> {
        navigate(PATHS.Global.CompanyKyc);
    }

    return (
        <main role="main" className="app-kyc-type">
            <div className="app-kyc-type__wrap">

                <GlobalLangaugeSelector className="app-kyc-type__lang-select" />

                <div className="app-kyc-type__content">
                    <div className="app-kyc-type__content-item app-kyc-type__content-item--form">
                        <h1 className="app-kyc-type__title">{`${t(StringResources.kycType.title)}`}</h1>
                        <>
                            <h3 className="app-kyc-type__subtitle">
                                {`${t(StringResources.kycType.description)}`}
                            </h3>
                            <div className="app-kyc-type-card-height">
                                <div className="app-kyc-type__card" role="button" onClick={() => handleIndividualClick()}>
                                    <div>
                                        <div className="app-kyc-type__card-name">{`${t(StringResources.kycType.individual)}`}</div>
                                        <div className="app-kyc-type__card-title">{`${t(StringResources.kycType.individualDescription)}`}</div>
                                    </div>
                                    <AngleIcon className="app-kyc-type__card-icon" />
                                </div>

                                <div className="app-kyc-type__card" role="button" onClick={() => handleCompanyClick()}>
                                    <div>
                                        <div className="app-kyc-type__card-name">{`${t(StringResources.kycType.company)}`}</div>
                                        <div className="app-kyc-type__card-title">{`${t(StringResources.kycType.companyDescription)}`} </div>
                                    </div>
                                    <AngleIcon className="app-kyc-type__card-icon" />
                                </div>
                            </div>
                        </>
                    </div>

                    <div className="app-kyc-type__content-item app-kyc-type__content-item--img">
                        <img
                            height="290"
                            width="240"
                            src={logo}
                            alt="Netgiro - Merchant Portal"
                            className="app-kyc-survey__sidebar-logo"
                        />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default KycType;
