import { AsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { SupportedLanguageEnum } from "./enums";
import i18n from "./language/languageClient";
import { ILookupResponse } from "./models";

export async function executeContainerThunkDispatch<ReturnType, ArgumentType>(
    containerDispatch: ThunkDispatch<any, any, any>,
    thunkAction: AsyncThunk<ReturnType, ArgumentType, {}>,
    args: ArgumentType
) {
    let isValid = false;
    let error = undefined;
    let data = {} as ReturnType;

    try {
        data = await containerDispatch(thunkAction(args)).unwrap();
        isValid = true;
    } catch (exception) {
        error = exception;
    }

    return { data, isValid, error };
}

export const devConsoleLog = (param1?: any, param2?: any) => {
    //process.env.NODE_ENV != "production" && console.log(param1, param2);
};

export const devConsoleError = (param1?: any, param2?: any) => {
    process.env.NODE_ENV != "production" && console.error(param1, param2);
};

export const devConsoleAssert = (condition: boolean) => {
    process.env.NODE_ENV != "production" && console.assert(condition);
};

export const getLanguages = (): ILookupResponse<number>[] => {
    return [
        { value: 1, name: SupportedLanguageEnum[SupportedLanguageEnum.Icelandic] },
        { value: 2, name: SupportedLanguageEnum[SupportedLanguageEnum.English] },
    ];
};

export const getDropdownValue = (value: string, defaultValue: string) => {
    return value.length > 1 ? value : defaultValue;
};

export function createUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export const handleChangeLanguage = (lng: string) => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
};

export function getFrontendVersion(): string {
    return process.env.BUILD_NUMBER ? "1.0." + process.env.BUILD_NUMBER : "dev";
}

export function kycLanguageDetect(
    languageString: string,
    iskText: string | undefined | null,
    engText: string | undefined | null
): string | undefined | null {
    const language = SupportedLanguageEnum[languageString as keyof typeof SupportedLanguageEnum];

    if (language === SupportedLanguageEnum.Icelandic) {
        return iskText;
    } else {
        if (engText) {
            return engText;
        }
        return iskText;
    }
}
