import { StringResources } from "utils/language/languageResource";

export const translationsIs = {
    [StringResources.modal.close]: "Hætta við",
    [StringResources.modal.confirm]: "Staðfesta",
    [StringResources.modal.delete]: "Henda",
    [StringResources.modal.cancel]: "Hætta við",
    [StringResources.modal.set]: "Stilla",
    [StringResources.modal.details]: "Nánar",
    [StringResources.modal.submit]: "Senda",
    [StringResources.modal.feedback.title]: "Endurgjöf",
    [StringResources.modal.feedback.label]: "Skilaboð",
    [StringResources.modal.feedback.placeholder]: "Skrifa skilaboð hér",
    [StringResources.modal.feedback.success]: "Skilaboð hafa verið send",
    [StringResources.modal.feedback.error]: "Skilaboð voru ekki send",
    [StringResources.modal.feedback.message]: "Þjónustuver Straums mun hafa samband við fyrsta tækifæri.",
    [StringResources.modal.deleteModal.title]: "Staðfesta eyðingu",
    [StringResources.modal.deleteModal.message]: "Ertu viss um að þú viljir eyða?",

    [StringResources.errorBoundary.title]: "Afsakaðu, eitthvað hefur farið úrskeðis",
    [StringResources.errorBoundary.subtitle]: "(Síðan verður endurræst eftir {{restartInterval}})",
    [StringResources.errorBoundary.btnText]: "Endurræsa núna",

    [StringResources.mainNavigation.dashboard]: "Mælaborð",
    [StringResources.mainNavigation.payments]: "Útborganir",
    [StringResources.mainNavigation.terminals]: "Útstöðvar",
    [StringResources.mainNavigation.agreements]: "Samningar",
    [StringResources.mainNavigation.dailyBalance]: "Dagleg staða",
    [StringResources.mainNavigation.settlements]: "Uppgjör",
    [StringResources.mainNavigation.batches]: "Bunkar",
    [StringResources.mainNavigation.transactions]: "Færslur",
    [StringResources.mainNavigation.authorizations]: "Heimildir",
    [StringResources.mainNavigation.disputes]: "Frávik",
    [StringResources.mainNavigation.users]: "Notendur",
    [StringResources.mainNavigation.electronicDocuments]: "Yfirlit",
    [StringResources.mainNavigation.notifications]: "Tilkynningar",
    [StringResources.mainNavigation.settings]: "Stillingar",
    [StringResources.mainNavigation.termsOfService]: "Notkunarskilmálar",
    [StringResources.mainNavigation.logout]: "Skrá út",
    [StringResources.userMenu.back]: "Til baka",

    [StringResources.table.cell.boolean.yesLabel]: "Já",
    [StringResources.table.cell.boolean.noLabel]: "Nei",
    [StringResources.table.fallbackMessage.default]: "Ekkert {{pageName}} fannst{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.none]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.notFound]: "Engin gögn fundust{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessageWithFilter]: " - prufaðu að breyta síu stillingunum",
    [StringResources.table.itemsPerPage]: "Atriði á síðu",
    [StringResources.report.export]: "Sækja gögn",
    [StringResources.report.print]: "Prenta",

    [StringResources.pages.notFound.title]: "Síða finnst ekki",
    [StringResources.pages.notFound.description]:
        "Síðan sem þú ert að reyna að skoða er ekki til eða þú hefur ekki leyfi til að skoða hana.",
    [StringResources.pages.notFound.link]: "Til baka",
    [StringResources.pages.none.title]: "Ekkert",

    [StringResources.pages.terms.title]: "Notendaskilmálar",
    [StringResources.pages.terms.link]: "Sækja skilmála á pdf formi",
    [StringResources.pages.terms.conditions]: "Ég samþykki notendaskilmála Straums",
    [StringResources.pages.terms.back]: "Skrá út",
    [StringResources.pages.terms.acceptTerms]: "Halda áfram",
    [StringResources.pages.terms.selectMerchant]: "Skráðu þig inn sem",
    [StringResources.pages.terms.descriptionFirst]: "Nauðsynlegt er að samþykkja",
    [StringResources.pages.terms.descriptionLink]: "notendaskilmála Straums",
    [StringResources.pages.terms.descriptionSecond]: "(gilda frá 1. janúar 2023) til að nota þjónustuvefinn.",

    [StringResources.pages.terms.acceptTerms]: "Samþykkja skilmála",
    [StringResources.pages.terms.userMessageAcceptTerms]:
        "Til að halda áfram að nota þjónustuvefinn verður stjórnandi að skrá sig inn og samþykkja notkunarskilmála.",
    [StringResources.pages.terms.newTermsAvailable.first]: "Við höfum uppfært viðskiptaskilmálana. Ýtið ",
    [StringResources.pages.terms.newTermsAvailable.second]: "hér",
    [StringResources.pages.terms.newTermsAvailable.third]: " til að hlaða niður nýrri útgáfu.",

    [StringResources.input.passwordShowCaption]: "Sýna",
    [StringResources.input.passwordHideCaption]: "Fela",
    [StringResources.input.passwordStrengthBarLabel]: "Styrkur lykilorðs",
    [StringResources.input.passwordStrengthBad]: "Mjög veikt",
    [StringResources.input.passwordStrengthWeak]: "Veikt",
    [StringResources.input.passwordStrengthPassable]: "Miðlungs",
    [StringResources.input.passwordStrengthGood]: "Sterkt",
    [StringResources.input.passwordStrengthVeryGood]: "Mjög sterkt",
    [StringResources.login.backActionLabel]: "Fara til baka",
    [StringResources.login.title]: "Þjónustuvefur",
    [StringResources.login.formTitle]: "Innskráning",
    [StringResources.login.formImpersonateTitle]: "Innskráning (Admin Impersonate Login)",
    [StringResources.login.formEmailInputLabel]: "Tölvupóstur",
    [StringResources.login.formEmailValidationMsg]: "Vinsamlegast sláðu inn virkt netfang",
    [StringResources.login.formPasswordInputLabel]: "Lykilorð",
    [StringResources.login.formButtonCaption]: "Skrá inn",
    [StringResources.login.forgotPasswordLink]: "Búin/nn að gleyma lykilorðinu?",
    [StringResources.login.loginFailed]: "Þú hefur slegið inn rangt notandanafn eða lykilorð",
    [StringResources.multiselect.selected]: "Valið",
    [StringResources.multiselect.options]: "Valmöguleikar",
    [StringResources.multiselect.selectAll]: "Velja allt",
    [StringResources.multiselect.clearAll]: "Hreinsa síu",

    [StringResources.versionCheck.text]:
        "Ný útgáfa af vefnum var að detta í hús með allskonar nýjungum - til að uppfæra þarf einungis að smella ",
    [StringResources.versionCheck.button]: "hérna!",

    [StringResources.kycType.title]: "Áreiðanleikakönnun",
    [StringResources.kycType.description]: "Veldu einstaklingur eða lögaðili",
    [StringResources.kycType.individual]: "Einstaklingur",
    [StringResources.kycType.individualDescription]: "Ef þú ert einstaklingur, smelltu þá hér til svara áreiðanleikakönnun.",
    [StringResources.kycType.company]: "Lögaðili",
    [StringResources.kycType.companyDescription]: "Ef þú ert lögaðili, smelltu þá hér til svara áreiðanleikakönnun.",
    [StringResources.kyc.backActionLabel]: "Til baka",
    [StringResources.kyc.title]: "Áreiðanleikakönnun",
    [StringResources.kyc.formTitle]: "Innskrá með rafrænum skilríkjum",
    [StringResources.kyc.formPhoneNumberInputLabel]: "Símanúmer",
    [StringResources.kyc.formButtonCaption]: "Innskráning",
    [StringResources.kyc.kycFailed]: "You have entered an invalid username or password",
    [StringResources.kyc.audkenniTabTitle]: "Rafræn skilríki",
    [StringResources.kyc.audkenniAppTabTitle]: "Auðkennisapp",
    [StringResources.kyc.audkenniAppLogin]: "Innskrá með Auðkennisapp",
    [StringResources.kyc.ssn]: "Kennitala",
    [StringResources.kyc.controlCode]: "Öryggistala:",
    [StringResources.kycLegalEntity.backActionLabel]: "Til baka",
    [StringResources.kycLegalEntity.title]: "Veljið lögaðila",
    [StringResources.kycLegalEntity.subTitle]:
        "Þú getur valið að svara Áreiðanleikakönnun fyrir hönd eftirfarandi lögaðila:",
    [StringResources.kycLegalEntity.empty]:
        "Því miður fundum við engin tengd félög til að svara áreiðanleikakönnun fyrir.  Vinsamlegast reynið aftur eða hafið samband við þjónustuver Netgíró fyrir frekari upplýsingar.",
    [StringResources.kycSurvey.backActionLabel]: "Til baka",
    [StringResources.kycSurvey.title]: "Áreiðanleikakönnun",
    [StringResources.kycSurvey.error]: "Það er nauðsynlegt að svara þessari spurningu",
    [StringResources.kycSurvey.changeButton]: "Change",
    [StringResources.kycSurvey.changeText]: "You are answering on behalf of ",
    [StringResources.kycSurvey.formButtonCaption]: "Senda svör",
    [StringResources.kycSurvey.beneficialOwner.fullName]: "Full name",
    [StringResources.kycSurvey.beneficialOwner.ssn]: "Kennitala",
    [StringResources.kycSurvey.beneficialOwner.shares]: "Shares",
    [StringResources.kycSurvey.beneficialOwner.phone]: "Phone",
    [StringResources.kycSurvey.beneficialOwner.addPerson]: "Bæta við stjórnarmanni",
    [StringResources.kycSurvey.beneficialOwner.title]: "Stjórnarmaður ",
    [StringResources.kycSurvey.legalBinder.fullName]: "Full name",
    [StringResources.kycSurvey.legalBinder.ssn]: "Kennitala",
    [StringResources.kycSurvey.legalBinder.position]: "Position",
    [StringResources.kycSurvey.legalBinder.phone]: "Phone",
    [StringResources.kycSurvey.legalBinder.addPerson]: "Bæta við stjórnarmanni",
    [StringResources.kycSurvey.legalBinder.title]: "Stjórnarmaður ",
    [StringResources.kycSurvey.legalBinder.positions.boardMember]: "Board manager",
    [StringResources.kycSurvey.legalBinder.positions.ceo]: "CEO",
    [StringResources.kycSurvey.legalBinder.positions.chairmanOfTheBoard]: "Chairman Of The Board",
    [StringResources.kycSurvey.legalBinder.positions.other]: "Other",
    [StringResources.kycSurvey.legalBinder.positions.powerOfAttorney]: "Power Of Attorney",
    [StringResources.kycSurvey.legalBinder.positions.comanager]: "Co-Manager",
    [StringResources.kycSurvey.reviewAnswer]: "Vinsamlegast athugaðu svarið",
    [StringResources.kycSurvey.genericError]: "Það er villa í KYC spurningalistanum. Vinsamlegast yfirfarið svörin.",
    [StringResources.kycSurvey.fetchQuestionarieError]: "Eitthvað fór úrskeiðis. Vinsamlegast reyndu aftur eftir smá.",
    [StringResources.kycSuccess.backActionLabel]: "Back to web",
    [StringResources.kycSuccess.title]: "Thank you for answering",
    [StringResources.kycSuccess.subTitle]:
        "Your answers have been submitted to Netgíró. We’re excited to get you onboard with us.",
    [StringResources.kycSuccess.buttonCaption]: "Back to netgiro.is",
};