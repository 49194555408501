import { StringResources } from "./languageResource";

export const translationsEn = {
    [StringResources.modal.close]: "Close",
    [StringResources.modal.confirm]: "Confirm",
    [StringResources.modal.delete]: "Delete",
    [StringResources.modal.cancel]: "Cancel",
    [StringResources.modal.set]: "Set",
    [StringResources.modal.details]: "Details",
    [StringResources.modal.submit]: "Submit",
    [StringResources.modal.feedback.title]: "Feedback",
    [StringResources.modal.feedback.label]: "Message",
    [StringResources.modal.feedback.placeholder]: "Type your reply here",
    [StringResources.modal.feedback.success]: "Feedback message has been sent successfully",
    [StringResources.modal.feedback.error]: "Feedback message didn't sent",
    [StringResources.modal.feedback.message]:
        "Netgiro support will see this Feedback message and we will reach out to you.",
    [StringResources.modal.column]: "Column",
    [StringResources.modal.filter]: "Filter",
    [StringResources.modal.deleteModal.title]: "Confirm Delete",
    [StringResources.modal.deleteModal.message]: "Are you sure you want to delete this item?",

    [StringResources.errorBoundary.title]: "Oops something went wrong!",
    [StringResources.errorBoundary.subtitle]: "(Refreshing in {{restartInterval}})",
    [StringResources.errorBoundary.btnText]: "Refresh now",

    [StringResources.mainNavigation.dashboard]: "Dashboard",
    [StringResources.mainNavigation.payments]: "Payments",
    [StringResources.mainNavigation.terminals]: "Terminals",
    [StringResources.mainNavigation.agreements]: "Agreements",
    [StringResources.mainNavigation.dailyBalance]: "Daily balance",
    [StringResources.mainNavigation.settlements]: "Settlements",
    [StringResources.mainNavigation.batches]: "Batches",
    [StringResources.mainNavigation.transactions]: "Transactions",
    [StringResources.mainNavigation.authorizations]: "Authorizations",
    [StringResources.mainNavigation.disputes]: "Disputes",
    [StringResources.mainNavigation.users]: "Users",
    [StringResources.mainNavigation.electronicDocuments]: "Statements",
    [StringResources.mainNavigation.notifications]: "Notifications",
    [StringResources.mainNavigation.settings]: "Settings",
    [StringResources.mainNavigation.termsOfService]: "Terms of service",
    [StringResources.mainNavigation.logout]: "Log out",
    [StringResources.userMenu.back]: "Back",

    [StringResources.table.cell.boolean.yesLabel]: "Yes",
    [StringResources.table.cell.boolean.noLabel]: "No",
    [StringResources.table.fallbackMessage.default]: "No {{pageName}} found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.none]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessage.notFound]: "No data found{{fallbackMessageAddon}}.",
    [StringResources.table.fallbackMessageWithFilter]: " - try adjusting your filter settings",
    [StringResources.table.itemsPerPage]: "Items per page",
    [StringResources.report.export]: "Export data",
    [StringResources.report.print]: "Print",

    [StringResources.pages.notFound.title]: "Page not found",
    [StringResources.pages.notFound
        .description]: `The page you are trying to view does not exist or you do not have permission to view it.`,
    [StringResources.pages.notFound.link]: "Back to KYC portal",
    [StringResources.pages.none.title]: "None",

    [StringResources.pages.terms.title]: "Terms of service",
    [StringResources.pages.terms.link]: "Download pdf document",
    [StringResources.pages.terms.conditions]: "I accept Stramur's terms of service",
    [StringResources.pages.terms.back]: "Logout",
    [StringResources.pages.terms.acceptTerms]: "Continue",
    [StringResources.pages.terms.selectMerchant]: "Log in as",
    [StringResources.pages.terms.descriptionFirst]: "Please read and accept",
    [StringResources.pages.terms.descriptionLink]: "Netgíró's Terms of Service",
    [StringResources.pages.terms.descriptionSecond]: "(valid from 1. January 2023) to use the Merchant Portal.",

    [StringResources.pages.terms.userMessageAcceptTerms]:
        "In order to continue using merchant portal, your organization's administrator needs to log in and accepts the terms of service",
    [StringResources.pages.terms.newTermsAvailable.first]: "Terms and conditions have been updated. Click ",
    [StringResources.pages.terms.newTermsAvailable.second]: "here",
    [StringResources.pages.terms.newTermsAvailable.third]: " to download the new version.",
    [StringResources.input.passwordShowCaption]: "Show",
    [StringResources.input.passwordHideCaption]: "Hide",
    [StringResources.input.passwordStrengthBarLabel]: "Password strength",
    [StringResources.input.passwordStrengthBad]: "Bad",
    [StringResources.input.passwordStrengthWeak]: "Weak",
    [StringResources.input.passwordStrengthPassable]: "Passable",
    [StringResources.input.passwordStrengthGood]: "Good",
    [StringResources.input.passwordStrengthVeryGood]: "Very Good",
    [StringResources.login.backActionLabel]: "Back to web",
    [StringResources.login.title]: "Merchant Portal",
    [StringResources.login.formTitle]: "Log in",
    [StringResources.login.formImpersonateTitle]: "Log in (Admin Impersonate Login)",
    [StringResources.login.formEmailInputLabel]: "Email",
    [StringResources.login.formEmailValidationMsg]: "Please enter a valid email address",
    [StringResources.login.formPasswordInputLabel]: "Password",
    [StringResources.login.formButtonCaption]: "Log in",
    [StringResources.login.forgotPasswordLink]: "Forgot your password?",
    [StringResources.login.loginFailed]: "You have entered an invalid username or password",
    [StringResources.multiselect.selected]: "Selected",
    [StringResources.multiselect.options]: "Options",
    [StringResources.multiselect.selectAll]: "Select All",
    [StringResources.multiselect.clearAll]: "Clear",

    [StringResources.versionCheck.text]:
        "We've just released a new update for the app which includes some great new features - so make sure you force update the app to get the latest and greatest by clicking ",
    [StringResources.versionCheck.button]: "here!",


    [StringResources.kycType.title]: "Due diligence",
    [StringResources.kycType.description]: "Select individual or company",
    [StringResources.kycType.individual]: "Individual",
    [StringResources.kycType.individualDescription]: "If you are an individual, click here to submit your KYC.",
    [StringResources.kycType.company]: "Company",
    [StringResources.kycType.companyDescription]: "If you are a company, click here to submit your KYC.",
    [StringResources.kyc.backActionLabel]: "Back to web",
    [StringResources.kyc.title]: "KYC Survey",
    [StringResources.kyc.formTitle]: "Log in with Auðkenni",
    [StringResources.kyc.formPhoneNumberInputLabel]: "Phone number",
    [StringResources.kyc.formButtonCaption]: "Log in",
    [StringResources.kyc.kycFailed]: "You have entered an invalid username or password",
    [StringResources.kyc.audkenniTabTitle]: "Auðkenni",
    [StringResources.kyc.audkenniAppTabTitle]: "Auðkenni app",
    [StringResources.kyc.audkenniAppLogin]: "Log in with Auðkenni app",
    [StringResources.kyc.ssn]: "SSN",
    [StringResources.kyc.controlCode]: "Control code:",
    [StringResources.kycLegalEntity.backActionLabel]: "Back to web",
    [StringResources.kycLegalEntity.title]: "Choose legal entity",
    [StringResources.kycLegalEntity.subTitle]: "You can answer the KYC survey on behalf of these legal entities:",
    [StringResources.kycLegalEntity.empty]:
        "There are no connected companies to answer KYC questionnaire for.  Please try again or contact Netgíró service desk for further information.",
    [StringResources.kycSurvey.backActionLabel]: "Back to web",
    [StringResources.kycSurvey.title]: "Áreiðanleikakönnun",
    [StringResources.kycSurvey.error]: "This question is required",
    [StringResources.kycSurvey.changeButton]: "Change",
    [StringResources.kycSurvey.changeText]: "You are answering on behalf of ",
    [StringResources.kycSurvey.formButtonCaption]: "Senda svör",
    [StringResources.kycSurvey.beneficialOwner.fullName]: "Full name",
    [StringResources.kycSurvey.beneficialOwner.ssn]: "SSN",
    [StringResources.kycSurvey.beneficialOwner.shares]: "Shares",
    [StringResources.kycSurvey.beneficialOwner.phone]: "Phone",
    [StringResources.kycSurvey.beneficialOwner.addPerson]: "Bæta við stjórnarmanni",
    [StringResources.kycSurvey.beneficialOwner.title]: "Stjórnarmaður ",
    [StringResources.kycSurvey.legalBinder.fullName]: "Full name",
    [StringResources.kycSurvey.legalBinder.ssn]: "SSN",
    [StringResources.kycSurvey.legalBinder.position]: "Position",
    [StringResources.kycSurvey.legalBinder.phone]: "Phone",
    [StringResources.kycSurvey.legalBinder.addPerson]: "Bæta við stjórnarmanni",
    [StringResources.kycSurvey.legalBinder.title]: "Stjórnarmaður ",
    [StringResources.kycSurvey.legalBinder.positions.boardMember]: "Board manager",
    [StringResources.kycSurvey.legalBinder.positions.ceo]: "CEO",
    [StringResources.kycSurvey.legalBinder.positions.chairmanOfTheBoard]: "Chairman Of The Board",
    [StringResources.kycSurvey.legalBinder.positions.other]: "Other",
    [StringResources.kycSurvey.legalBinder.positions.powerOfAttorney]: "Power Of Attorney",
    [StringResources.kycSurvey.legalBinder.positions.comanager]: "Co-Manager",
    [StringResources.kycSurvey.reviewAnswer]: "Please, review your answer",
    [StringResources.kycSurvey.genericError]: "There is an error with KYC questionnaire. Please review your answers.",
    [StringResources.kycSurvey.fetchQuestionarieError]: "Something went wrong. Please try again later.",
    [StringResources.kycSuccess.backActionLabel]: "Back to web",
    [StringResources.kycSuccess.title]: "Thank you for answering",
    [StringResources.kycSuccess.subTitle]:
        "Your answers have been submitted to Netgíró. We’re excited to get you onboard with us.",
    [StringResources.kycSuccess.buttonCaption]: "Back to netgiro.is",
};
