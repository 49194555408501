export enum SupportedLanguageEnum {
    Icelandic = 1,
    English = 2,
}

export enum RoleType {
    Admin = 1,
    User = 2,
    ApiKey = 3,
}

export enum PageTypeEnum {
    None = "none",
    NotFound = "notFound",
}