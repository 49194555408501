import classNames from "classnames";
import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { AngleIcon } from "components/icons";
import "./baseDropdown.scss";
import FilterClearIcon from "components/icons/filterClearIcon";
import { StringResources } from "utils/language/languageResource";
import { t } from "i18next";
import BaseButton from "components/baseButton/baseButton";

interface BaseDropdownProps {
    title?: string;
    className?: string;
    toggleText: string;
    labelText?: string;
    customMenu?: boolean;
    customIcon?: React.ReactNode;
    size?: "default" | "small";
    children: React.ReactNode;
    onSelect: (eventKey: any, event: Object) => void;
    clearable?: boolean;
    disabled?: boolean;
}

const BaseDropdown: FC<BaseDropdownProps> = ({
    title,
    className,
    toggleText,
    labelText,
    customMenu,
    customIcon,
    size = "default",
    children,
    onSelect,
    clearable,
    disabled
}) => (
    <>
        {labelText && <label className="base-dropdown__label">{labelText}</label>}

        <Dropdown
            title={title}
            className={classNames(`base-dropdown ${className ?? ""}`, {
                "base-dropdown--small": size === "small",
                "base-dropdown--disabled": disabled,
            })}
            onSelect={onSelect}
        >
            <Dropdown.Toggle className="base-dropdown__toggle-action" disabled={disabled}>
                {toggleText}
                {customIcon ? customIcon : <AngleIcon className="base-dropdown__toggle-action-icon" />}
            </Dropdown.Toggle>
            <Dropdown.Menu className="base-dropdown__menu">
                {children}
            </Dropdown.Menu>
        </Dropdown>

        {clearable && toggleText != title && <div className="d-flex justify-content-end">
            <BaseButton
                handleClick={() => onSelect("", "")}
                // text={`${t(StringResources.filter.clear)}`}
                styleType="text"
            />
        </div>}
    </>
);

export default BaseDropdown;