import { IconSVGProps } from "./types";

const CloseIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.2717 7.61104C18.7923 7.09045 18.7923 6.24502 18.2717 5.72443C17.7511 5.20384 16.9057 5.20384 16.3851 5.72443L11.9997 10.114L7.61007 5.72859C7.08948 5.208 6.24404 5.208 5.72345 5.72859C5.20286 6.24918 5.20286 7.09462 5.72345 7.61521L10.1131 12.0007L5.72761 16.3903C5.20703 16.9108 5.20703 17.7563 5.72761 18.2769C6.2482 18.7975 7.09364 18.7975 7.61423 18.2769L11.9997 13.8873L16.3893 18.2727C16.9099 18.7933 17.7553 18.7933 18.2759 18.2727C18.7965 17.7521 18.7965 16.9067 18.2759 16.3861L13.8863 12.0007L18.2717 7.61104Z"
            fill="#002649"
        />
    </svg>
);

export default CloseIcon;
