export const reduxAction = {
    resetStore: "store/reset",
};

export const ignoreLoader = [
    "api",
    "modal"
];

export const mobileScreenResolution = "(max-width: 768px)";

export const defaultSeriesColorPallet = [
    "#C5F1FF",
    "#78B2FF",
    "#DB78FF",
    "#FF78C9",
    "#FF7878",
    "#FFA578",
    "#FAF751",
    "#C3F363",
    "#BCE6F3",
    "#6698D9",
    "#8166D9",
    "#BA66D9",
    "#D966AB",
    "#D96666",
    "#D98C66",
    "#D9D766",
    "#B2D966",
];

export const maxNumberOfItemsToExport = 100_000;
