import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingActionModel } from "redux/models/authModels";

export interface IAuthState {
    loadingActions: Array<LoadingActionModel>;
}

const initialState: IAuthState = {
    loadingActions: [],
};

const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        addLoaderAction(state: IAuthState, action: PayloadAction<LoadingActionModel>) {
            state.loadingActions.push(action.payload);
        },
        removeLoaderAction(state: IAuthState, action: PayloadAction<string>) {
            state.loadingActions = state.loadingActions.filter(x => x.requestId != action.payload);
        },
    },
    extraReducers: builder => {
    },
});

const { reducer } = authReducer;
export const { addLoaderAction, removeLoaderAction } = authReducer.actions;
export default reducer;
