export const PATHS = {
    Global: {
        Index: "/",
        CompanyKyc: "/company",
        Kyc: "/kyc",
        KycLegalEntity: "/company/legal-entity",
        KycSurvey: "/company/legal-entity/survey",
        KycSuccess: "/company/success",
        IndividualKyc: "/individual",
        IndividualKycSurvey: "/individual/survey",
        IndividualKycSuccess: "/individual/success",

        NotFound: "/not-found",
    },
    Portal: {
        Index: "/",
    },
};
