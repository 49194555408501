import "hammerjs";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from "redux/store";
import { PATHS } from "utils/routing/paths";
import { KycWrapperLazy, globalElements } from "utils/routing/pathsAndElements";
import { FallbackComponent } from "./fallbackComponent";
import GlobalRoutesWrapper from "./wrappers/globalRoutesWrapper";
import KycLegalEntity from "../../pages/kycLegalEntity/kycLegalEntity";
import KycType from "pages/kycType/kycType";

const NotFoundLazy = React.lazy(() => import("../../pages/notFound/notFound"));
const KycLazy = React.lazy(() => import("../../pages/kyc/kyc"));
const KycLegalEntityLazy = React.lazy(() => import("../../pages/kycLegalEntity/kycLegalEntity"));
const KycSurveyLazy = React.lazy(() => import("../../pages/kycSurvey/kycSurvey"));
const KycSuccessLazy = React.lazy(() => import("../../pages/kycSuccess/kycSuccess"));

const KycIndividualSurveyLazy = React.lazy(() => import("../../pages/kycIndividualSurvey/kycIndividualSurvey"));

const MainLayout = () => {

    return (
        <Suspense fallback={<FallbackComponent />}>
            <Routes>
                <>
                    {globalElements.map(element => {
                        return (
                            <Route
                                key={element.path}
                                path={element.path}
                                element={
                                    <GlobalRoutesWrapper>
                                        <element.element />
                                    </GlobalRoutesWrapper>
                                }
                            />
                        );
                    })}

                    <Route path={PATHS.Global.Index} element={<KycWrapperLazy />}>
                        <Route index element={<KycType />}></Route>
                    </Route>

                    <Route path={PATHS.Global.CompanyKyc} element={<KycWrapperLazy />}>
                        <Route index element={<KycLazy />}></Route>
                        <Route path={PATHS.Global.KycLegalEntity} element={<KycLegalEntityLazy />}></Route>
                        <Route path={PATHS.Global.KycSurvey} element={<KycSurveyLazy />}></Route>
                        <Route path={PATHS.Global.KycSuccess} element={<KycSuccessLazy />}></Route>
                    </Route>

                    <Route path={PATHS.Global.IndividualKyc} element={<KycWrapperLazy />}>
                        <Route index element={<KycLazy />}></Route>
                        <Route path={PATHS.Global.IndividualKycSurvey} element={<KycIndividualSurveyLazy />}></Route>
                        <Route path={PATHS.Global.IndividualKycSuccess} element={<KycSuccessLazy />}></Route>
                    </Route>

                    <Route path={"/*"} element={<NotFoundLazy />} />
                </>
            </Routes>
        </Suspense>
    );
};

export default MainLayout;
