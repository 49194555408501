import { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "utils/routing/paths";
import { getRedirectToLastRoute, setRedirectToLastRoute } from "utils/storageActions";
import { FallbackComponent } from "../fallbackComponent";


interface GlobalRoutesWrapperProps {
    children: React.ReactNode;
}
const GlobalRoutesWrapper = ({ children }: GlobalRoutesWrapperProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const locationState = location.state as any;
        const redirect = getRedirectToLastRoute();

        if (redirect && locationState?.from) {
            navigate(locationState.from, { replace: true });
        } else {
            setRedirectToLastRoute(true);
            navigate(PATHS.Global.CompanyKyc);
        }
    }, []);

    return <Suspense fallback={<FallbackComponent />}>
        {children}
    </Suspense>
};


export default GlobalRoutesWrapper;
