import { useAppDispatch } from "redux/store";
import BaseToastContainer from "containers/baseToastContainer/baseToastContainer";
import { ErrorBoundary } from "containers/errorBoundary/errorBoundary";
import MainLayout from "containers/layout/mainLayout";
import MainLoader from "containers/mainLoader/mainLoader";
import "hammerjs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { removeUserLocalStorageData } from "utils/storageActions";
import "react-toastify/dist/ReactToastify.css";

const App = () => {

    return (
        <div className="app">
            <ErrorBoundary>
                <MainLoader />
                {(
                    <>
                        <BaseToastContainer />
                        <MainLayout />
                    </>
                )}
            </ErrorBoundary>
        </div>
    );
};

export default App;
