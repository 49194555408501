
import React from "react";
import ErrorRefreshPage from "./pages/errorRefreshPage";
import * as Sentry from '@sentry/browser';

interface IErrorBoundaryProps {
    fallback?: React.ReactNode
    children: React.ReactNode
}
export class ErrorBoundary extends React.Component<IErrorBoundaryProps> {

    state: { hasError: boolean }
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        // eslint-disable-next-line no-console
        console.log({ error, errorInfo });
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.hasError) {
            return <>
                {this.props.fallback || <ErrorRefreshPage />}
            </>;
        }

        return this.props.children;
    }
}


