import React from "react";
import { StringResources } from "utils/language/languageResource";
import { PATHS } from "./paths";

const NotFoundLazy = React.lazy(() => import("../../pages/notFound/notFound"));

import { IconSVGProps } from "components/icons/types";

export const globalElements = [
    {
        path: PATHS.Global.NotFound,
        element: NotFoundLazy,
    },
];

export const KycWrapperLazy = React.lazy(() => import("../../containers/layout/wrappers/kycWrapper"));
